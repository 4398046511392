import api from '@/api/api'
import config from '@/config'

export async function getVolume(data) {
  return await api.post(`${config.upload_url}/calculate-volume`, data.params)
}

export async function getVolumeImage(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/map/images/${data.imageId}/volume`,
    data.payload,
  )
}
