<template>
  <v-layout class="fill-height overflow-hidden" column>
    <v-layout class="fill-height overflow-hidden">
      <div v-show="leftMenu" style="width: 340px; height: 100%; flex: none;">
        <v-layout column fill-height style="position: relative;">
          <v-btn class="map-overview-menu" color="bgContainer" fab @click="leftMenu = false">
            <v-icon size="26">icon-chevrons_left</v-icon>
          </v-btn>
          <v-layout class="pb-2; overflow-hidden" style="flex: 1">
            <v-card class="AOI-menu custom-card-bg-gradient" height="100%" width="100%">
              <LayerControl
                ref="layerControl"
                :current-layers.sync="layers"
                :isDrawing.sync="isDraw"
                comparable
                map-control
                removable
                transparent
                @cancel="cancelCreate"
                @changeCompare="show => $refs.map.changeCompare(show)"
                @changeToSyncMap="status => ($refs.map.mapSync = status)"
                @changeVisible="layer => $refs.map.changeVisibility(layer.id)"
                @changeVisibleLeftLayer="(layer, isSync) => $refs.map.changeVisibleLeftLayer(layer.id)"
                @createLayer="createLayer"
                @reloadMap="() => $refs.map.reloadMap()"
                @removeAllLayer="() => $refs.map.removeAllLayer()"
                @removeLayer="layerId => $refs.map.removeLayer(layerId)"
                @saveLayer="saveLayer"
                @updateStyleProperties="layerId => $refs.map.updateStyleProperties(layerId)"
                @zoomTo="bbox => $refs.map.submitZoom(bbox)"
              />
            </v-card>
          </v-layout>
          <v-layout class="pt-2 overflow-y-hidden" style="flex: 1; min-height: 200px">
            <v-card class="AOI-menu custom-card-bg-1" height="100%" width="100%">
              <v-layout class="fill-height pa-3 pt-5" column>
                <div style="flex: none; min-height: 0; width: 100%">
                  <v-layout class="fill-height">
                    Detail
                    <v-spacer />
                    <v-btn
                      :disabled="!this.currentAOI"
                      color="primary"
                      height="38"
                      min-height="0"
                      min-width="0"
                      small
                      width="38"
                      @click="isCreating = true"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-layout>
                </div>
                <div class="pt-3 overflow-y-hidden" style="flex: 1">
                  <v-layout class="fill-height overflow-y-hidden" style="width: 100%">
                    <v-data-table
                      :headers="headers"
                      :items="features"
                      :loading="dataLoading"
                      class="hide-per-page table-small-text fill-height"
                      fixed-header
                      height="100%"
                      hide-default-footer
                      style="width: 100%"
                    >
                      <template #[`item.area`]="{item}">
                        <div v-html="item.area"></div>
                      </template>
                      <template #[`item.volume`]="{item}">
                        <div v-html="item.volume"></div>
                      </template>
                    </v-data-table>
                  </v-layout>
                </div>
              </v-layout>
            </v-card>
          </v-layout>
        </v-layout>
      </div>
      <v-layout class="fill-height pl-4" column>
        <div class="pb-2" style="width: 100%; flex: none">
          <v-layout class="fill-height">
            <v-col class="pl-0" cols="12" lg="4">
              <select2
                v-model="currentAOI"
                :items="listAOI"
                dense
                hide-details
                item-text="name"
                item-value="uuid"
                label="Select AOI"
                outlined
                placeholder="AOI"
                return-object
                @change="getService"
              />
              <!--              <v-select-->
              <!--                v-model="currentAOI"-->
              <!--                :items="AOIs"-->
              <!--                :loading="loading"-->
              <!--                dense-->
              <!--                hide-details-->
              <!--                item-text="name"-->
              <!--                label="Select AOI"-->
              <!--                outlined-->
              <!--                placeholder="AOI"-->
              <!--                return-object-->
              <!--                @change="getService"-->
              <!--              />-->
            </v-col>
            <v-col cols="12" lg="4">
              <v-select
                v-model="source"
                :disabled="!Object.keys(service).length"
                :items="sources"
                :loading="loading"
                dense
                hide-details
                item-text="name"
                label="Select source"
                outlined
                placeholder="Source"
                return-object
                @change="getListResult"
              />
            </v-col>
            <v-col cols="12" lg="4">
              <v-select
                v-model="result"
                :disabled="!source"
                :items="data"
                :loading="loading"
                dense
                hide-details
                item-text="date"
                label="Date"
                outlined
                placeholder="Date"
                return-object
                @change="getLayers"
              >
              </v-select>
            </v-col>
            <v-col cols="12" lg="0"></v-col>
          </v-layout>
        </div>
        <v-card height="100%" style="position: relative" width="100%">
          <v-btn
            v-if="!leftMenu"
            color="bgContainer"
            fab
            style="position: absolute; bottom: 12px; left: 12px; z-index: 2"
            @click="leftMenu = true"
          >
            <v-icon>icon-layers</v-icon>
          </v-btn>
          <v-btn-toggle v-if="isCreating" style="position: absolute; top: 12px; left: 60px; z-index: 2">
            <v-btn small width="120" @click="getVolume">
              <v-icon class="mr-1" small>mdi-content-save-outline</v-icon>
              Submit
            </v-btn>
            <v-btn small width="120" @click="isCreating = false">
              <v-icon class="mr-1" small>mdi-cancel</v-icon>
              close
            </v-btn>
          </v-btn-toggle>
          <div
            style="width: 55px; height: 280px; max-height: 90%; position: absolute; top: 60px; left: 20px; z-index: 2"
          >
            <div style="width: 10px; height: 100%;" v-bind:style="{ 'background-image': gradient }"></div>
            <div
              v-for="(item, index) in legends"
              v-show="index % 2 === 0"
              style="position: absolute; right: 0; width: 100%; height: 1px; border: 1px solid black; font-size: 11px; color: black; text-align: end"
              v-bind:style="{ top: 100 - index * 5 + '%' }"
            >
              {{ item.value.toFixed(2) }}
            </div>
          </div>
          <Map
            ref="map"
            :current-layers.sync="layers"
            :drawType.sync="drawType"
            :isCreateText="displayType === 'text'"
            :isDraw="isCreating || isDraw"
          />
        </v-card>
      </v-layout>
    </v-layout>
  </v-layout>
</template>

<script>
import Map from '@/components/Map'
import LayerControl from '@/components/layer-control/LayerControl.vue'
import { mapState } from '@/store/ults'
import { getOrder } from '@/api/order'
import { getDetailAOI } from '@/api/aoi-api'
import { getImageResult } from '@/api/change-detection-api'
import { getVolume, getVolumeImage } from '@/api/volume-api'
import AreaConvert from '@/utils/textArea'
import CustomIcon from '@/components/CustomIcon.vue'
import VectorStyle from '@/utils/VectorStyle'
import numberFormat from '@/utils/comma'
import utils from '@/utils/genUUID'
import GradientColor from '@/utils/genGradiendColor'
import Select2 from '@/components/Select2/Select2.vue'

export default {
  name: 'LandSubsidence',
  components: { Select2, CustomIcon, LayerControl, Map },
  data() {
    return {
      isNotification: false,
      isDraw: false,
      layerName: '',
      drawType: '',
      displayType: '',
      legends: [],
      firstLoad: true,
      isCreating: false,
      dataLoading: false,
      loading: false,
      layers: [],
      source: undefined,
      currentAOI: undefined,
      leftMenu: true,
      sources: [],
      service: {},
      data: [],
      result: undefined,
      features: [],
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Area',
          align: 'start',
          sortable: true,
          value: 'area',
        },
        {
          text: 'Volume',
          align: 'start',
          sortable: false,
          value: 'volume',
        },
      ],
    }
  },
  props: {},
  computed: {
    ...mapState('AOI', ['AOIs', 'listAOI']),
    gradient() {
      let colors = [...this.legends]
      return 'linear-gradient(' + colors.map(val => val.color).toString() + ')'
    },
    notificationId() {
      return this.$route.query?.noti
    },
  },
  async mounted() {
    await this.initDataNoti()
  },
  watch: {
    async notificationId() {
      await this.initDataNoti()
    },
    leftMenu() {
      this.$refs.map.reSize()
    },
    layers(val) {
      let removedLayer = []
      this.features.forEach(vector => {
        if (!this.layers.some(layer => layer.id === vector.id)) {
          removedLayer.push(vector)
        }
      })
      removedLayer.forEach(layer => {
        layer.display = false
        let index = this.features.findIndex(vector => vector.id === layer.id)
        if (index >= 0) this.features.splice(index, 1)
      })
      if (!this.layers.some(layer => layer.id === this.currentAOI.uuid)) this.currentAOI = undefined
    },
  },
  methods: {
    async initDataNoti() {
      const query = this.$route.query
      if (query.notification && this.notificationId) {
        const noti = await this.$store.dispatch('notification/getNotification', query.noti)
        this.currentData = {
          ...noti.reference_data?.order,
          aoi: noti.reference_data?.aoi,
          service: noti.reference_data?.service,
        }
        this.isNotification = true
      }
      this.getListAOI()
    },
    cancelCreate() {
      this.drawType = undefined
      this.layerName = ''
      this.$refs.map.deleteAllDraw()
      this.isDraw = false
    },
    saveLayer() {
      let features = this.$refs.map.getAllDrawFeature()
      let vectorType
      switch (this.displayType) {
        case 'polygon':
        case 'line':
          vectorType = 'line'
          break
        case 'point':
          vectorType = 'circle'
          break
      }
      if (this.displayType === 'marker') {
        features.forEach(feature => {
          feature.properties = {}
          for (let i = 0; i < feature.geometry.coordinates.length; i++) {
            feature.geometry.coordinates[i] = feature.geometry.coordinates[i].toFixed(4)
          }
          feature.properties.text = feature.geometry.coordinates.toString().replaceAll(',', ', ')
        })
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.map.addMarkerSymbolToMap(this.layerName, featureCollection, 'yellow')
      } else if (this.displayType === 'text') {
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.map.addTextToMap(this.layerName, featureCollection, 'yellow')
      } else {
        features.forEach(feature => {
          feature.properties = {}
          feature.properties.text = feature.geometry.coordinates.toString()
        })
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.map.addGeoJsonToMap(this.layerName, featureCollection, 'yellow', utils.getUUID(), vectorType, true)
      }
      this.drawType = undefined
      this.layerName = ''
      this.$refs.map.deleteAllDraw()
      this.isDraw = false
    },

    createLayer(name, type) {
      this.displayType = type
      this.layerName = name
      this.$refs.map.createLayer(name, type)
      switch (type) {
        case 'polygon':
        case 'line':
          this.drawType = 'polygon'
          break
        case 'marker':
        case 'text':
          this.drawType = 'point'
          break
      }
      this.isDraw = true
    },
    async getVolume() {
      try {
        this.loading = true
        let params = {
          file_path: '/data/2/5/25038009-4b01-423d-bad0-5a88af8b310a.tif',
          geometry: {},
        }
        let features = this.$refs.map.getAllDrawFeature()

        params.geometry = features[0].geometry
        const res = await getVolume({ params: params })
        let name = 'Polygon-' + this.features.length
        let id = utils.getUUID()
        this.features.push({
          name: name,
          area: this.transformArea(res.data.data.area),
          volume: numberFormat.numberWithCommas((res.data.data.volume / 1e9).toFixed(4)) + ' km<sup>3</sup>',
          id: id,
        })
        this.$refs.map.resetDraw()
        let geojson = {
          type: 'FeatureCollection',
          features: [features[0]],
        }
        this.$refs.map.addGeoJsonToMap(name, geojson, 'red', id, 'line', true)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getListAOI() {
      try {
        this.resetData()
        this.loading = true
        await this.$store.dispatch('AOI/getListAOI', {
          projectId: this.$route.params.id,
          payload: { service_name: this.$route.meta.service },
        })
        if (this.firstLoad) {
          if (this.isNotification) {
            this.currentAOI = this.AOIs.find(val => val.uuid === this.currentData.aoi.uuid)
          } else {
            this.currentAOI = this.AOIs[0]
          }
        }
        if (!this.currentAOI) this.firstLoad = false
        // else await this.getService()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getService() {
      try {
        this.loading = true
        this.resetData()
        await this.getInfoAOI()
        this.$refs.map.submitZoom(this.currentAOI.bbox)
        const res = await getOrder({
          projectId: this.$route.params.id,
          id: this.currentAOI.uuid,
          payload: { service_name: this.$route.meta.service },
        })
        if (isNotification) {
          this.service = res.data.find(val => val.service_id === this.currentData.service.id)
        } else {
          this.service = res.data[0]
        }
        this.sources = []
        res.data.forEach(val => this.sources.push(val.image_source))
        this.source = this.sources[0]
        if (this.source) await this.getListResult()
        else this.firstLoad = false
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getListResult() {
      try {
        this.loading = true
        this.currentDate = {}
        this.compareDate = {}
        this.chartData = {}
        this.result = undefined
        this.data = []
        const res = await getImageResult({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          payload: {
            service_id: this.service.service_id,
            source: this.source,
            per_page: 'all',
          },
        })
        this.data = res.data
        if (this.firstLoad) this.result = this.data[0]
        if (this.result) this.getLayers()
      } catch (e) {
      } finally {
        this.firstLoad = false
        this.loading = false
      }
    },
    async getLayers() {
      try {
        this.loading = true
        let removeLayers = this.layers.filter(layer => layer.id !== this.currentAOI.uuid)
        removeLayers.forEach(layer => {
          this.$refs.map.removeLayer(layer.id)
        })
        const res = await getVolumeImage({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          imageId: this.result.uuid,
          payload: { source: this.source },
        })
        res.data.data.forEach((val, index) => {
          if (index === res.data.data.length - 1) val.tile_info.name = val.name
          if (val.tile_info.type === 'images') {
            let image = {
              id: val.name,
              display: index === res.data.data.length - 1,
              data: val.tile_info,
              layerType: 'Raster_Image',
              name: val.name,
              tiles: [val.tile_info.tile_url],
              bounds: val.tile_info.bbox,
            }
            this.$refs.map.addImageToMap(image)
          } else {
            this.$refs.map.addVectorTiles({
              display: index === res.data.length - 1,
              data: val.tile_info,
              tiles: [val.tile_info.tile_url],
              bounds: val.tile_info.bbox,
              layerName: 'default',
              paint: VectorStyle.getStyle(val.tile_info.styles),
              name: val.name,
              id: val.name,
              type: val.tile_info.styles.type,
            })
          }
        })

        this.genLegends(res.data.legends.min, res.data.legends.max, res.data.legends.colors)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    genLegends(min = 0, max = 20, colorArr = []) {
      let colors = GradientColor.generate_colors(colorArr, 21)
      let step = (max.toFixed(2) - min.toFixed(2)) / 20
      this.legends = []
      for (let i = 0; i < 21; i++) {
        this.legends.push({
          color: colors[colors.length - i - 1].color,
          value: min + i * step,
        })
      }
    },
    async getInfoAOI() {
      try {
        this.loading = true
        this.$refs.map.removeAllLayer()
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: this.currentAOI.uuid })
        res.data.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        this.$refs.map.addGeoJsonToMap(res.data.name, res.data.geojson, 'red', res.data.uuid, 'line', true)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    transformArea(area) {
      return AreaConvert.covertArea(area)
    },
    resetData() {
      this.source = undefined
      this.data = []
      this.result = undefined
    },
  },
}
</script>

<style scoped></style>
