import Rainbow from '@/utils/Rainbow'

const GradientColor = {
  generate_colors(colors, amount) {
    let no_colors = colors.length
    let step = (amount - 1) / (no_colors - 1)
    let start = 1
    let listColors = []
    let listIndexes = []
    let rainbow = new Rainbow()

    // color ramp 2 màu trên từng đoạn
    for (let i = 1; i < no_colors; i++) {
      let end = start + step
      rainbow.setNumberRange(start, end)
      rainbow.setSpectrum(colors[i - 1], colors[i])

      // Lấy các màu có index là integer
      for (let j = Math.ceil(start); j <= end; j++) {
        if (!listIndexes.includes(j)) {
          listColors.push(rainbow.colorAt(j))
          listIndexes.push(j)
        }
      }
      start = end
    }
    if (listColors.length < amount) {
      listColors.push(colors[no_colors - 1])
    }
    return listColors.map(c => ({ color: '#' + c }))
  },
}

export default GradientColor
