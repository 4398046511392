import Store from '@/store'
import numberFormat from '@/utils/comma'

export class TextArea {
  covertArea(area, unit = Store.state.setting.unit, withUnit = true) {
    switch (unit) {
      case 'km':
        if (withUnit) return numberFormat.numberWithCommas(Math.round((area / 1000000) * 100) / 100) + ' km<sup>2</sup>'
        return numberFormat.numberWithCommas(Math.round((area / 1000000) * 10000) / 10000)
      case 'ha':
        if (withUnit) return numberFormat.numberWithCommas(Math.round((area / 10000) * 100) / 100) + ' ha'
        return numberFormat.numberWithCommas(Math.round((area / 10000) * 10000) / 10000)
      default:
        if (withUnit) return numberFormat.numberWithCommas(Math.round(area * 100) / 100) + ' m<sup>2</sup>'
        return numberFormat.numberWithCommas(Math.round(area * 100) / 100)
    }
  }

  covertNumberArea(area, unit) {
    switch (unit) {
      case 'km':
        return Math.round((area / 1000000) * 100) / 10
      case 'ha':
        return Math.round((area / 10000) * 100) / 100
      default:
        return Math.round(area * 100) / 100
    }
  }
}

const AreaConvert = new TextArea()

export default AreaConvert
