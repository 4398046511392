import api from '@/api/api'
import config from '@/config'

export async function getOrderAdmin(data) {
  return await api.get(`${config.backend}/system/orders`, data)
}

export async function getPipelineAdmin(data) {
  return await api.get(`${config.backend}/system/pipelines`, data)
}

export async function getDataFilterOrder(data) {
  return await api.get(`${config.backend}/filter/order-data`, data)
}

export async function getAllAOI(data) {
  return await api.get(`${config.backend}/system/aois`, data)
}

export async function reSumitTask(id) {
  return await api.post(`${config.backend}/system/tasks/${id}/resubmit`)
}

export async function runOrder(id) {
  return await api.post(`${config.backend}/system/tasks/${id}/run`)
}

export async function uploadData(id, data={}) {
  return await api.post(`${config.backend}/system/tasks/${id}/upload-data`, data)
}

export async function makeOrder(data) {
  return await api.post(`${config.backend}/projects/${data.projectId}/aois/${data.id}/orders`, data.params)
}

export async function getOrder(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/aois/${data.id}/orders`, {
    ...data.payload,
    ...{ per_page: 'all' },
  })
}

export async function getAllOrder(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/orders`, data.payload)
}

export async function updateOrder(data) {
  return await api.put(`${config.backend}/projects/${data.projectId}/aois/${data.AOIId}/orders/${data.orderId}`)
}

export async function getOrderStatus(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/orders/${data.orderId}/pipelines`,
    data.payload,
  )
}

export async function getSourceProperties(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.aoiId}/services/${data.serviceId}/sources/${data.source}/properties`,
    data.payload,
  )
}

export async function getType(data) {
  return await api.get(`${config.backend}/services/${data.serviceId}/filter-data`)
}

export async function getPropertyTask(id, data) {
  return await api.get(`${config.backend}/system/tasks/${id}/property`, data)
}
