import api from '@/api/api'
import config from '@/config'

export async function getOverview(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/dashboard/classification-change-detection/overview`,
    data.payload,
  )
}

export async function getDataTopResult(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/top-10-results`, data.payload)
}

export async function getChangeDetectionResult(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/aois/${data.id}/vectors`, data.payload)
}

export async function getVector(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/vectors/${data.vectorId}/features`,
    data.payload,
  )
}

export async function getImageResult(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/aois/${data.id}/images`, data.payload)
}

export async function getFeature(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/vectors/${data.vectorId}/features/${data.featureId}`,
    data.payload,
  )
}

export async function getChangeDetectionLayer(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/map/vectors/${data.vectorId}/change-detection`,
    data.payload,
  )
}

export async function getChangeDetectionTimeSerial(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/dashboard/classification-change-detection/time-series`,
    data.payload,
  )
}
